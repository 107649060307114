<template>
  <v-app>
    <v-row class="mx-0 my-0">
      <!-- Segunda tarjeta -->
      <v-col cols="12" md="2">
        <v-card class="pa-2">
          <v-row justify="center">
            <td>
              <div class="mb-4 mt-4">
                <h3>Etiquetas:</h3>
                <span class="etiqueta GLP">CONSULTA NUTRICIONAL</span>
                <span class="etiqueta TSHAPE">T-SHAPE</span>
                <span class="etiqueta SLIMUP">SLIM-UP</span>
                <span class="etiqueta FACIALES">FACIALES</span>
                <span class="etiqueta DEPILACION">DEPILACION</span>
                <span class="etiqueta FITPASS">FIT-PASS</span>
                <span class="etiqueta GLP_T_SHAPE">NUT + TSHAPE</span>
                <span class="etiqueta ULTRAFORMER">ULTRAFORMER</span>
                <span class="etiqueta VALORACION">VALORACION</span>
                <span class="etiqueta SESION">SESION TOMADA</span>
              </div>
            </td>
          </v-row>
        </v-card>
      </v-col>

      <!-- Tarjeta donde está el calendario -->
      <v-col cols="12" md="10">
        <v-card>
          <v-row class="mx-0 mt-0">
            <v-col>
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn outlined class="mr-4" color="#35CDCD" @click="setToday">
                    Mes
                  </v-btn>
                  <v-btn fab text small color="#35CDCD" @click="prev">
                    <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn fab text small color="#35CDCD" @click="next">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn outlined class="mr-4" color="#35CDCD" @click="crearEvento">
                    Crear evento
                  </v-btn>
                  <v-btn outlined class="mr-4" color="#35CDCD" @click="changeView('day')">
                    Día
                  </v-btn>
                </v-toolbar>
              </v-sheet>

              <v-sheet height="800">
                <v-calendar ref="calendar" v-model="focus" color="#35CDCD" :events="allEvents"
                  :event-color="getEventColor" :type="type" @click:event="showEvent" @click:more="viewDay"
                  @click:date="viewDay" :locale="'es'" interval-height="80">
                  <template v-slot:event="event">
                    <div :style="{ marginLeft: '10px' }">
                      <!-- Solo mostramos el nombre del evento, sin la hora -->
                      <span>{{ event.event.name }}</span>
                    </div>
                  </template>
                </v-calendar>

                <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
                  <v-card min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color">
                      <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <!-- Información del evento -->
                      <div>
                        <span v-html="selectedEvent.details"></span>
                        <v-divider></v-divider>
                        <span v-html="selectedEvent.hora"></span>
                      </div>
                      <br>
                      <!-- Información adicional sobre el creador y la fecha -->
                      <div>
                        Creado por {{ selectedEvent.createdBy }} el {{ selectedEvent.createdDate }}
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="#00000" @click="selectedOpen = false">
                        Cerrar
                      </v-btn>
                      <v-btn v-if="selectedEvent.isFromEventos" text color="red" @click="eliminarEvento(selectedEvent)">
                        Eliminar
                      </v-btn>
                    </v-card-actions>

                  </v-card>
                </v-menu>

              </v-sheet>

            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Modal de eventos -->
    <v-dialog v-model="modalOpen" max-width="600px">
      <v-card>
        <v-card-title>
          <span>Eventos del {{ (formattedDate) }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="modalOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item-group v-if="filteredEvents.length">
              <v-list-item v-for="event in filteredEvents" :key="event.id" :style="getItemStyle(event)">
                <v-list-item-content>
                  <v-list-item-title
                    :style="{ color: event.asistio === 1 ? 'grey' : getColorByServicio(event.details) }">
                    {{ event.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ event.hora }} - {{ event.details }}
                  </v-list-item-subtitle>
                  <v-list-item>
                    Creado por {{ event.createdBy }} el {{ event.createdDate }}
                  </v-list-item>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item v-else>
              <v-list-item-content>No hay eventos para este día.</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>

    <!-- CREACION DE EVENTOS TIPO NOTAS PARA RECORDATORIOS -->
    <v-dialog v-model="formularioEvento" max-width="700">
      <v-card>
        <v-card-title color="#35CDCD">
          <span class="text-h5" color="#35CDCD">Crear Evento</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="12">
                <v-text-field label="Titulo del Evento" outlined color="#35CDCD" id="nuevoEvento_name"
                  v-model="nuevoEvento_name" type="text" required></v-text-field>
              </v-col>

              <!-- Diálogo de Fecha -->
              <v-col cols="12" md="6">
                <v-dialog v-model="modalFecha" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaEvento" label="Fecha" outlined color="#35CDCD"
                      prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="fechaEvento" color="#35CDCD" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="red" @click="modalFecha = false">Cancelar</v-btn>
                    <v-btn text color="#35CDCD" @click="modalFecha = false">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <!-- Diálogo de Hora -->
              <v-col cols="12" md="6">
                <v-dialog v-model="modalHora" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="horaEvento" label="Hora" outlined color="#35CDCD"
                      prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-model="horaEvento" color="#35CDCD" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="red" @click="modalHora = false">Cancelar</v-btn>
                    <v-btn text color="#35CDCD" @click="modalHora = false">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12">
                <v-textarea label="Detalles" outlined color="#35CDCD" id="nuevoEvento_details"
                  v-model="nuevoEvento_details" type="text" required></v-textarea>
              </v-col>

              <!-- Botón para abrir selector de color y recuadro para mostrar el color seleccionado -->
              <v-col cols="12" sm="4" md="4">
                <v-btn outlined class="mr-4" color="#35CDCD" @click="selectorDeColor = true">
                  Color del Evento
                </v-btn>
              </v-col>

              <!-- Recuadro para mostrar el color seleccionado -->
              <v-col cols="12" sm="4" md="4">
                <div
                  :style="{ backgroundColor: nuevoEvento_color, width: '100px', height: '30px', border: '1.5px solid grey' }">
                </div>
              </v-col>

            </v-row>
          </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="formularioEvento = false">
              Cerrar
            </v-btn>
            <v-btn type="submit" color="#35CDCD" text @click="guardarEvento">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card-text>

      </v-card>
    </v-dialog>

    <!-- Diálogo del selector de color con botones centrados -->
    <v-dialog v-model="selectorDeColor" max-width="300">
      <v-card>
        <v-color-picker v-model="nuevoEvento_color" flat></v-color-picker>
        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="4">
              <v-btn color="#35CDCD" text @click="selectorDeColor = false">
                Seleccionar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para mostrar alerta -->
    <v-dialog v-model="alertaformulario" max-width="400">
      <v-card>
        <v-card-text class="text-center pa-4">
          <span class="headline">¡Alerta!</span>
          <div style="margin-top: 30px;"> <!-- Espacio adicional entre el span y el párrafo -->
            <p>Por favor, complete todos los campos obligatorios.</p>
          </div>
          <v-card-actions class="justify-center">
            <v-btn color="#35CDCD" text @click="alertaformulario = false">Entendido</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>


<script>
import axios from 'axios';
import { API_HOST } from '../utils/constants.js';

export default {
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mes",
      week: "Semana",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    modalOpen: false,
    filteredEvents: [], // Arreglo para almacenar los eventos filtrados por fecha
    events: [], // Arreglo para almacenar eventos
    formattedDate: '', // Fecha formateada para mostrar en el modal
    sesiones: [],

    snackbar: false,
    snackbarText: '',
    timeout: 3000,

    tipoServicio: '',

    alertaformulario: false,
    formularioEvento: false,
    selectorDeColor: false,
    modalFecha: false,
    modalHora: false,
    fechaEvento: '',
    horaEvento: '',

    nuevoEvento_name: '',
    nuevoEvento_start: '',
    nuevoEvento_details: '',
    nuevoEvento_color: '',

    eventos: [], // Arreglo para almacenar eventos sin union
    roleUser: '',
    fechaFormateada: '',
  }),

  mounted() {
    this.$refs.calendar.checkChange();

    this.fetchEventos();
  },

  created() {
    this.fetchVentas().then(() => {
      this.fetchCitas();
    }).catch(error => {
      alert.error('Error al obtener datos:', error);
    });
  },

  computed: {
    allEvents() {
      return [
        ...this.events, // Eventos que no se pueden eliminar
        ...this.eventos // Eventos que se pueden eliminar
      ];
    }
  },

  methods: {

    async fetchEventos() {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/eventos`);
        const data = response.data.body;

        if (Array.isArray(data)) {
          this.eventos = data.reduce((acc, evento) => {
            // Procesa cada evento
            const startDate = new Date(evento.start);
            const endDate = new Date(evento.end);

            // Ajusta la fecha de inicio y fin a la zona horaria de Ciudad de México
            startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset()); // UTC
            endDate.setMinutes(endDate.getMinutes() + endDate.getTimezoneOffset());

            // Si las fechas no son válidas, las omite
            if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
              console.error('Fecha inválida:', startDate, endDate);
              return acc;
            }

            // Asegura que las fechas estén bien formateadas
            const startFormatted = this.formatearFecha(startDate);
            const endFormatted = this.formatearFecha(endDate);

            if (!startFormatted || !endFormatted) {
              console.error('Fecha mal formateada:', startDate, endDate);
              return acc;
            }

            // Asegura que createdDate sea una fecha válida
            let createdDate = new Date(evento.createdDate);
            if (isNaN(createdDate.getTime())) {
              console.warn('Fecha de creación inválida:', evento.createdDate);
              createdDate = null; // O maneja esto según tus necesidades
            } else {
              createdDate = createdDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD
            }

            // Crea el nuevo objeto evento
            return acc.concat({
              idEventos: evento.idEventos,  // Ajusta esto según lo que devuelva el API
              name: evento.name,
              start: startFormatted,
              end: endFormatted,
              color: evento.color,
              details: evento.details,
              hora: evento.hora,
              createdBy: evento.createdBy,
              createdDate: createdDate, // Ya en el formato correcto
              isFromEventos: true // Para eventos que pueden ser eliminados
            });
          }, []); // Reduce a una lista de eventos
        } else {
          this.snackbarText = 'La respuesta de la API no es una lista';
          this.snackbar = true;
        }
      } catch (error) {
        console.error('Error al obtener los eventos comunes:', error);
        this.snackbarText = 'Error al obtener los eventos comunes';
        this.snackbar = true;
      }
    },


    async eliminarEvento(evento) {
      try {
        const idEventos = evento.idEventos; // Asegúrate de obtener correctamente el ID

        // Llamada al backend para eliminar el evento
        await axios.delete(`https://${API_HOST}/api/vista/eventos/${idEventos}`);

        // Elimina el evento localmente
        this.eventos = this.eventos.filter(e => e.id !== idEventos);
        this.selectedOpen = false; // Cierra el menú después de eliminar

        this.snackbarText = 'Evento eliminado con éxito.';
        this.snackbar = true;

        this.fetchEventos();

      } catch (error) {
        console.error('Error al eliminar el evento:', error);
        this.snackbarText = 'Error al eliminar el evento.';
        this.snackbar = true;
      }
    },

    obtenerFechaActual() {
      const fecha = new Date(); // Obtiene la fecha actual
      const anio = fecha.getFullYear(); // Obtiene el año
      const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Obtiene el mes (0-11) y lo convierte a formato 2 dígitos
      const dia = String(fecha.getDate()).padStart(2, '0'); // Obtiene el día y lo convierte a formato 2 dígitos

      return `${anio}-${mes}-${dia}`; // Retorna la fecha en el formato deseado
    },

    crearEvento() {
      this.formularioEvento = true;
    },

    guardarEvento() {
      if (!this.nuevoEvento_name || !this.fechaEvento || !this.horaEvento) {
        this.alertaformulario = true;
        return;
      }

      this.fechaFormateada = this.obtenerFechaActual();
      this.roleUser = sessionStorage.getItem('user');

      // Combina la fecha del evento con la hora, sin ajustes de UTC
      const startDate = new Date(`${this.fechaEvento}T${this.horaEvento}:00`); // 'T' para separar la fecha y la hora
      if (isNaN(startDate.getTime())) {
        console.error('Fecha de inicio inválida');
        return;
      }

      // Crea la fecha de fin (1 hora después de la fecha de inicio)
      const endDate = new Date(startDate);
      endDate.setHours(startDate.getHours() + 1);

      // Formatea las fechas de inicio y fin para asegurarse de que están correctas
      const startFormatted = this.formatearFecha(startDate);
      const endFormatted = this.formatearFecha(endDate);

      if (!startFormatted || !endFormatted) {
        console.error('Fechas mal formateadas');
        return;
      }

      // Crear un nuevo objeto evento
      const nuevoEvento = {
        idEventos: 0,
        name: this.nuevoEvento_name,
        start: startFormatted, // Usa la fecha formateada correctamente
        end: endFormatted,
        hora: this.horaEvento,
        details: this.nuevoEvento_details,
        color: this.nuevoEvento_color,
        createdBy: this.roleUser,
        createdDate: this.fechaFormateada,
      };

      // Hacer la solicitud POST a la API para guardar el evento
      axios.post(`https://${API_HOST}/api/vista/eventos`, nuevoEvento)
        .then(() => {
          this.snackbarText = 'Evento guardado con éxito';
          this.snackbar = true;

          this.fetchEventos(); // Refrescar la lista de eventos

          // Limpiar el formulario
          this.nuevoEvento_name = '';
          this.fechaEvento = '';
          this.horaEvento = '';
          this.nuevoEvento_details = '';
          this.nuevoEvento_color = '#35CDCD';

          // Cerrar el formulario
          this.formularioEvento = false;
        })
        .catch((error) => {
          error
          this.snackbarText = 'Error al guardar el evento';
          this.snackbar = true;
        });
    },

    changeView(view) {
      this.type = view;
    },

    viewDays({ date }) {
      this.focus = date;
      this.type = 'day';
    },

    formatDateToFullString(dateString) {
      const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
      const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

      const date = new Date(dateString);

      const dayOfWeek = daysOfWeek[date.getDay()];
      const day = date.getDate().toString().padStart(2, '0');
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${dayOfWeek} ${day} de ${month} del ${year}`;
    },
    getItemStyle(event) {
      return {
        backgroundColor: event.asistio === 1 ? '#d0d0d0' : 'white', // Color más oscuro si asistio === 1
        borderRadius: '4px', // Opcional, para suavizar las esquinas
        marginBottom: '10px' // Opcional, para espaciar los elementos
      };
    },

    async fetchVentas() {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/sesiones`);
        const data = response.data.body;
        if (Array.isArray(data)) {
          this.sesiones = data.map(sesion => ({
            idSesion: sesion.idSesion,
            idCita: sesion.idCita,
            fecha: this.formatearFecha(sesion.fecha),
            Hora: sesion.Hora,
            createdBy: sesion.createdBy,
            createdDate: sesion.createdDate,
            asistio: sesion.asistio,
            tipoServicio: sesion.tipoServicio,
          }));
        } else {
          this.snackbarText = 'La respuesta de la API no es válida';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener las sesiones';
        this.snackbar = true;
      }
    },

    formatearFecha(fecha) {
      const padZero = (number) => (number < 10 ? '0' : '') + number;
      try {
        // Asegúrate de que 'fecha' es una cadena de texto o un objeto Date
        let date;
        if (typeof fecha === 'string') {
          date = new Date(fecha);
        } else if (fecha instanceof Date) {
          date = fecha;
        } else {
          return '';
        }

        return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
      } catch (error) {
        error
        this.snackbarText = 'Error al formatear la fecha';
        this.snackbar = true;
        return '';
      }
    },


    fetchCitas() {
      axios
        .get(`https://${API_HOST}/api/vista/ventas/`)
        .then((response) => {
          const data = response.data.body;
          if (Array.isArray(data)) {
            this.events = data.reduce((acc, cita) => {
              const sesiones = this.sesiones.filter((sesion) => sesion.idCita === cita.idCita);
              return acc.concat(
                sesiones.map((sesion) => {
                  const startDate = new Date(sesion.fecha);
                  startDate.setMinutes(startDate.getMinutes() + startDate.getTimezoneOffset()); // Ajusta la fecha a UTC

                  const startTime = sesion.Hora.split(':');
                  startDate.setHours(startTime[0]);
                  startDate.setMinutes(startTime[1]);

                  // Verifica si startDate es una fecha válida antes de continuar
                  if (isNaN(startDate.getTime())) {
                    console.error('Fecha inválida:', sesion.fecha);
                    return;
                  }

                  // Crear la fecha de fin (endDate) basada en el tipo de servicio
                  const endDate = new Date(startDate);
                  if (sesion.tipoServicio === "NUT" || cita.nombre_servicio === "CONSULTA-NUTRICIONAL") {
                    // Si es NUT o CONSULTA-NUTRICIONAL, la duración es de 30 minutos
                    endDate.setMinutes(startDate.getMinutes() + 30);
                  } else {
                    // Para otros tipos de servicio, la duración es de 1 hora
                    endDate.setHours(startDate.getHours() + 1);
                  }

                  // Ajusta createdDate para que coincida con la hora local
                  let createdDate = new Date(sesion.createdDate);
                  createdDate.setMinutes(createdDate.getMinutes() + createdDate.getTimezoneOffset()); // Ajusta a UTC
                  createdDate.setHours(createdDate.getHours()); // Ajusta la hora si es necesario

                  // Asegurarse de que las fechas estén en el formato correcto
                  const startFormatted = this.formatearFecha(startDate);
                  const endFormatted = this.formatearFecha(endDate);
                  const createdFormatted = this.formatearFecha(createdDate);

                  if (!startFormatted || !endFormatted) {
                    console.error('Fecha mal formateada:', startDate, endDate);
                    return;
                  }

                  if (cita.nombre_servicio === 'NUT+TSHAPE') {
                    this.tipoServicio = sesion.tipoServicio;
                  } else {
                    this.tipoServicio = '-';
                  }

                  return {
                    id: `${cita.idCita}-${sesion.idSesion}`,
                    name: `${cita.nombre_cliente} ${cita.apellido_cliente}`,
                    start: startFormatted,
                    end: endFormatted,
                    color: this.getColorByServicio(cita.nombre_servicio),
                    details: `${cita.nombre_servicio} (${this.tipoServicio})`,
                    hora: sesion.Hora,
                    createdBy: sesion.createdBy,
                    createdDate: createdFormatted,
                    asistio: sesion.asistio,
                  };
                }).filter(Boolean) // Filtra los valores inválidos
              );
            }, []);
          } else {
            this.snackbarText = 'La respuesta de la API no es una lista';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          error
          this.snackbarText = 'Error al obtener las citas';
          this.snackbar = true;
        });
    },




    getColorByServicio(nombre_servicio) {
      const primeraPalabra = nombre_servicio.split(' ')[0].toUpperCase();
      switch (primeraPalabra) {
        case "CONSULTA-NUTRICIONAL":
          return "#35CDCD";
        case "T-SHAPE":
          return "#c8c826";
        case "SLIM-UP":
          return "#78b817";
        case "FACIALES":
          return "#3af52e";
        case "DEPILACION":
          return "#f52e2e";
        case "FITPASS":
          return "#f52ea1";
        case "NUT+TSHAPE":
          return "#0b57f7";
        case "ULTRAFORMER":
          return "#f7870b";
        case "VALORACION":
          return "#8b00f6";
        default:
          return "#b9b9b9";
      }
    },

    viewDay({ date }) {
      //this.focus = date;
      this.openMoreModal(date); // Filtrar y mostrar los eventos de esa fecha
    },


    getEventColor(event) {
      return event.asistio === 1 ? 'grey' : event.color;
    },

    setToday() {
      this.focus = "";
      this.type = 'month';
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    openMoreModal(date) {

      // Convertir la fecha seleccionada a objeto Date para manipular correctamente
      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0); // Asegurarse de que el tiempo esté en medianoche
      // Agregar un día a la fecha seleccionada
      const nextDayDate = new Date(selectedDate);
      nextDayDate.setDate(nextDayDate.getDate() + 1); // Agregar un día
      // Actualizar la fecha formateada para el modal
      this.formattedDate = this.formatDateToFullString(nextDayDate);
      // Filtrar eventos para el día siguiente
      this.filteredEvents = this.allEvents.filter(event => {
        // Convertir la fecha de inicio del evento a objeto Date
        const eventDate = new Date(event.start);
        eventDate.setHours(0, 0, 0, 0); // Asegurarse de que el tiempo esté en medianoche
        // Comparar solo las fechas (sin tiempo)
        return eventDate.getTime() === nextDayDate.getTime();
      });

      this.modalOpen = true;
    }

  },
};
</script>


<style>
.etiqueta {
  background-color: #f52e2e;
  margin: 5px;
  padding: 5px 10px;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  width: 95%;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.etiqueta.GLP {
  background-color: #35CDCD;
}

.etiqueta.TSHAPE {
  background-color: #c8c826;
}

.etiqueta.SLIMUP {
  background-color: #78b817;
}

.etiqueta.FACIALES {
  background-color: #3af52e;
}

.etiqueta.DEPILACION {
  background-color: #f52e2e;
}

.etiqueta.FITPASS {
  background-color: #f52ea1;
}

.etiqueta.GLP_T_SHAPE {
  background-color: #0b57f7;
}

.etiqueta.ULTRAFORMER {
  background-color: #f7870b;
}

.etiqueta.VALORACION {
  background-color: #8b00f6;
}

.etiqueta.SESION {
  background-color: #b0b0b0;
  color: #ffffff;
}

h3 {
  text-align: center;
  color: black;
  font-family: arial, sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 1px;
}
</style>
