<template>
  <div>
    <template>
      <!-- Mostrar mensaje si no se seleccionó un cliente -->
      <v-col cols="12" v-if="!idCliente">
        <v-alert color="yellow darken-2">
          <v-icon>mdi-alert</v-icon> No se encontró un cliente.
        </v-alert>
      </v-col>
      <template v-else>
        <v-col cols="12" md="12" class="mx-auto">
          <v-col cols="12" md="12">
            <v-card-title>
              <h3 class="mx-0 mt-0">Paquetes comprados por {{ nombre }} {{ apellido }}</h3>

              <v-spacer></v-spacer>

              <!-- NOTIFICACIÓN PARA CUANDO SE LOGRE LLEGAR A LA META DE REGISTRO DE CLIENTES = 50 POR RECEPCION -->
              <!-- Dialogo para mostrar alerta -->
              <!-- MENSAJE DE NOTIFICACION PARA CUANDO LA CUENTA DE RECEPCION LLEGUE A REGISTRAR 50 CLIENTES -->
              <template>
                <div v-if="showNotificationButton" class="notification-icon">
                  <v-icon color="grey" @click="openDialogNoti">mdi-message-badge</v-icon>
                  <v-badge color="red" content="1"></v-badge>
                </div>
              </template>
              <v-spacer></v-spacer>


              <div>
                <v-dialog v-model="alertaformulario" persistent max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="#35CDCD" dark v-bind="attrs" v-on="on">
                      Agregar Paquete
                    </v-btn>
                  </template>

                  <v-card>
                    <v-btn icon class="close-button" @click="alertaformulario = false">
                      <v-icon color="red">mdi-close-box</v-icon></v-btn>
                    <v-card-text class="text-center">
                      <Sesiones :idCliente="selectedClientId" @guardar-paquete="handleCerrar2" />
                      <v-card-actions class="justify-center">
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
            </v-card-title>

            <!-- TABLA PRINCIPAL DE PAQUETES -->
            <v-data-table :headers="filteredHeaders" :items="formattedVentas()" :search="search" item-key="idCita"
              :expanded.sync="expanded" show-expand :footer-props="footerProps"
              @item-expanded="fetchSessions($event.item.idCita)">

              <!-- TABLA DENTRO DE CADA PAQUETE -TABLA DE SESIONES- -->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="padding: 0;">
                  <v-data-table :headers="applyRoleBasedHeaders2(item.nombre_servicio)"
                    :items="sesionesPorCita[item.idCita]" item-key="idCita" :footer-props="footerProps"
                    @item-expanded="fetchSessions($event.item.idCita)">

                    <template v-slot:no-data>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>No hay sesiones disponibles</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </template>

                    <template v-slot:[`item.asistio`]="{ item }">
                      <v-checkbox v-model="checkboxStates[item.idSesion]" @click="handleCheckboxChange(item)"
                        :disabled="disabledCheckboxes[item.idSesion]" color="#35cdcd" class="mx-1"></v-checkbox>
                    </template>

                    <!-- Boton para acciones dentro de sesiones -->
                    <template v-slot:[`item.actions`]="{ item }">

                      <!-- Boton para editar sesiones -->
                      <v-icon size="25px" color="#35CDCD" class="mx-1" @click.stop="editItem(item)" item-key="idSesion">
                        mdi-pencil
                      </v-icon>

                      <!-- Boton para eliminar sesiones -->
                      <v-icon v-if="roleUser !== 'standard'" size="25px" color="red" class="mx-1"
                        @click="confirmDeleteSesion(item)" item-key="idSesion">
                        mdi-delete
                      </v-icon>
                    </template>

                    <template v-slot:[`item.details`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-icon size="25px" color="#35CDCD">
                              mdi-account-multiple-outline
                            </v-icon>
                          </span>
                        </template>
                        <span>
                          Creado por: {{ item.createdBy || 'N/A' }} el {{ formatDate(item.createdDate) || 'N/A' }}
                          <br>
                          Última modificación por: {{ item.lastModifiedBy || 'N/A' }} el {{
                            formatDate(item.lastModifiedDate) || 'N/A' }}
                        </span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </td>
              </template>

              <!-- AGREGAR UNA NUEVA SESION A LA VENTA YA EXISTENTE -->
              <template v-slot:[`item.actions2`]="{ item }">

                <div v-if="item.nombre_servicio === 'NUT+TSHAPE'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon size="25px" @click="openAgregarSesionDialogNUT_TSHAPE(item.idCita)" color="#35CDCD"
                          class="mx-7" :disabled="disabledAgregarSesion[item.idCita]">
                          mdi-plus-box
                        </v-icon>
                      </span>
                    </template>
                    <span>
                      Agregar Nueva Sesión NUT + TSHAPE
                    </span>
                  </v-tooltip>
                </div>

                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon size="25px" @click="openAgregarSesionDialog(item.idCita)" color="#35CDCD" class="mx-7"
                          :disabled="disabledAgregarSesion[item.idCita]">
                          mdi-plus-box
                        </v-icon>
                      </span>
                    </template>
                    <span>
                      Agregar Nueva Sesión
                    </span>
                  </v-tooltip>
                </div>

              </template>

              <!-- ELIMINAR UNA VENTA/PAQUETE DE LA LISTA (RECORDAR QUE ESTO ELIMINARA A SU VEZ LAS SESIONES DE ESA CITA/VENTA/PAQUETE) -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon v-if="roleUser !== 'standard'" size="25px" color="red" class="mx-1"
                  @click.stop="confirmDeleteCita(item)" item-key="idCita">
                  mdi-delete
                </v-icon>
              </template>

              <template v-slot:[`item.details`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon size="25px" color="#35CDCD">
                        mdi-account-multiple-outline
                      </v-icon>
                    </span>
                  </template>
                  <span>
                    Creado por: {{ item.createdBy || 'N/A' }} el {{ formatDate(item.createdDate) || 'N/A' }}
                  </span>
                </v-tooltip>
              </template>

            </v-data-table>

            <!-- Dialogo de Confirmación de Eliminación de Una Cita/Venta/Paquete-->
            <v-dialog v-model="dialogDeleteCita" max-width="390">
              <v-card>
                <v-card-title class="text-center">Eliminar el paquete </v-card-title>
                <v-card-text>¿Estás seguro de que quieres eliminar este paquete?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="dialogDeleteCita = false">Cancelar</v-btn>
                  <v-btn color="#35CDCD" text @click="fetchDeleteCita">Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Dialogo de Confirmación de Eliminación -->
            <v-dialog v-model="dialogDelete" max-width="390">
              <v-card>
                <v-card-title class="text-center">Eliminar sesión </v-card-title>
                <v-card-text>¿Estás seguro de que quieres eliminar esta sesión?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="dialogDelete = false">Cancelar</v-btn>
                  <v-btn color="#35CDCD" text @click="fetchDeleteSesion">Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Dialogo de Confirmación de registrar asistencia -->
            <v-dialog v-model="dialogEdit" max-width="390">
              <v-card>
                <v-card-title class="text-center">Confirmar registro </v-card-title>
                <v-card-text>¿Estás seguro de confirmar la asistencia?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="cancelEdit">Cancelar</v-btn>
                  <v-btn color="#35CDCD" text @click="confirmEdit">Confirmar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


          </v-col>
        </v-col>


        <template>
          <v-dialog v-model="alertaformulario2" max-width="400px">
            <v-card>
              <v-btn icon class="close-button" @click="alertaformulario2 = false">
                <v-icon color="red">mdi-close-box</v-icon></v-btn>
              <v-card-text class="text-center">
                <v-row class="justify-center ma-auto">
                  <h2 class="pa-5">Agregar Sesión</h2>
                </v-row>
                <AgregarSesion :tipoServicio="null" :idCita="selectedCitaId" @guardar-sesion="handleCerrar"
                  @guardar-snakbar="handleGuardar" />
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="modalVisible3" max-width="1000px">
            <v-card>
              <v-btn icon class="close-button" @click="modalVisible3 = false">
                <v-icon color="red">mdi-close-box</v-icon></v-btn>
              <v-card-text class="text-center">
                <Agenda />
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="alertaGLP" max-width="400px">
            <v-card>
              <v-btn icon class="close-button" @click="closeDialogNoti">
                <v-icon color="red">mdi-close-box </v-icon> </v-btn>
              <v-card-title>
                NUT Terminado
              </v-card-title>
              <v-card-text class="ma-auto">
                Por favor considera realizar estudio al paciente ({{ nombre }} {{ apellido }}) antes de vender
                nuevamente un paquete que contenga NUT.
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>

      </template>
    </template>

    <!-- CAMPO PARA PODER EDITAR LA FECHA Y LA HORA ACTUALES DE LA SESION -->
    <template>
      <v-dialog v-model="dialogEditarSesion" max-width="400px">
        <v-card>
          <v-btn icon class="close-button" @click="dialogEditarSesion = false">
            <v-icon color="red">mdi-close-box</v-icon>
          </v-btn>

          <v-card-title class="justify-center">
            Editar datos de sesión
          </v-card-title>

          <v-row class="justify-center ma-auto" cols="12" sm="12">

            <!-- Diálogo de Fecha -->
            <v-col cols="12" md="12">
              <v-dialog v-model="modalFecha" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="fechaEditar" label="Fecha" outlined color="#35CDCD" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="fechaEditar" color="#35CDCD" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="red" @click="modalFecha = false">Cancelar</v-btn>
                  <v-btn text color="#35CDCD" @click="modalFecha = false">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- Diálogo de Hora -->
            <v-col cols="12" md="12">
              <v-dialog v-model="modalHora" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="horaEditar" label="Hora" outlined color="#35CDCD"
                    prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-model="horaEditar" color="#35CDCD" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="red" @click="modalHora = false">Cancelar</v-btn>
                  <v-btn text color="#35CDCD" @click="modalHora = false">OK</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col>
              <v-row>
                <v-col class="d-flex justify-start">
                  <v-btn @click="modalVisible3 = true" color="#35CDCD" block dark>Ver agenda</v-btn>
                </v-col>
              </v-row>

              <v-card-actions class="justify-center pa-5">
                <v-btn color="#35CDCD" text @click="fetchEditarSesion">Guardar</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </template>


    <v-dialog v-model="alertaformularioNUT_TSHAPE" max-width="400px">
      <v-card>
        <v-btn icon class="close-button" @click="alertaformularioNUT_TSHAPE = false">
          <v-icon color="red">mdi-close-box</v-icon>
        </v-btn>
        <v-card-text class="text-center">
          <v-row class="justify-center ma-auto">
            <h2>Por favor presione el botón que corresponde al tipo de servicio que quiere agendar:</h2>
          </v-row>
          <v-row class="justify-center ma-0 pa-5">
            <!-- Botón para NUT -->
            <v-col class="d-flex justify-center">
              <v-btn color="#35CDCD" dark :disabled="disabledButtons.NUT" @click="seleccionarServicio('NUT')">
                NUT
              </v-btn>
            </v-col>
            <!-- Botón para TSHAPE -->
            <v-col class="d-flex justify-center">
              <v-btn color="#35CDCD" dark :disabled="disabledButtons.TSHAPE" @click="seleccionarServicio('TSHAPE')">
                TSHAPE
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Componente hijo para agregar sesión -->
        <AgregarSesion :tipoServicio="tipoServicio" :idCita="selectedCitaId" v-if="tipoServicio"
          @guardar-sesion="handleCerrar" @guardar-snakbar="handleGuardar" />

      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ snackbarText }}
    </v-snackbar>

  </div>
</template>

<script>
import Sesiones from '@/views/SesionesView.vue';
import AgregarSesion from '@/views/AgregarSesionView.vue';
import Agenda from '@/views/AgendaView.vue';
import axios from '../axios';
import { API_HOST } from '../utils/constants.js';

export default {
  name: "VentasView",
  props: {
    idCliente: {
      type: Number,
    }
  },
  components: {
    Sesiones,
    AgregarSesion,
    Agenda,
  },
  data() {
    return {
      alertaGLP: false,
      citasRestantes: null,
      ventas: [],
      citas: [],
      sessions: {},
      expanded: [],
      search: '',
      nombre: "..",
      apellido: "..",
      fecha: '',
      hora: '',
      usapluma: '',
      asistio: '',
      dialog: false,
      modalVisible3: false,
      alertaformulario: false,
      alertaformulario2: false,
      alertaformularioNUT_TSHAPE: false,
      selectedClientId: null,
      selectedCitaId: null,
      selectedItems: null,
      footerProps: {

        itemsPerPageText: '',
        itemsPerPageAllText: '',
        showFirstLastPage: false,
        showCurrentPage: false,
        showSelect: false
      },

      sesiones: [],
      snackbarText: '',
      snackbar: false,
      timeout: 3000,
      checkboxStates: {},
      dialogEdit: false,
      dialogDelete: false,
      dialogDeleteCita: false,
      disabledCheckboxes: {},
      idcitaactualizar: null,
      idclienteactualizar: null,
      filteredHeaders: [],
      filteredHeaders2: [],
      sesionesPorCita: {},
      itemToEdit: '',
      servicio: '',
      showNotificationButton: false,
      disabledAgregarSesion: {},
      roleUser: sessionStorage.getItem('role'),
      modal: false,
      modal2: false,
      isChecked: false,
      time: null,
      date: null,
      dialogEditarSesion: false,
      fechaEditar: '',
      horaEditar: '',
      sesionAEditar: null,
      nombreServicio: '',
      total: 0,
      tipoServicio: '',
      disabledButtons: {
        NUT: false,
        TSHAPE: false,
      },

      modalFecha: false, // Estado del diálogo de fecha
      modalHora: false, // Estado del diálogo de hora

    }
  },

  mounted() {
    if (this.idCliente) {
      this.fetchPerfil(this.idCliente);
      this.applyRoleBasedHeaders();
    }
  },

  watch: {
    disabledAgregarSesion() {
      this.guardarEstadoAgregarSesion();
    },
  },

  methods: {

    seleccionarServicio(servicio) {
      this.tipoServicio = servicio; // Actualiza el tipo de servicio según el botón presionado
      if (this.tipoServicio == 'NUT') {
        this.disabledButtons.NUT = true;
        this.disabledButtons.TSHAPE = false;
      } else {
        this.disabledButtons.NUT = false;
        this.disabledButtons.TSHAPE = true;
      }
    },

    editItem(item) {
      this.sesionAEditar = item.idSesion;
      this.fechaEditar = item.fecha;
      this.horaEditar = item.hora;
      this.dialogEditarSesion = true;
    },

    async fetchEditarSesion() {
      try {
        // eslint-disable-next-line
        await axios.put(`https://${API_HOST}/api/vista/sesiones/update/uptime/${this.sesionAEditar}`, {
          fecha: this.fechaEditar,
          Hora: this.horaEditar,
          lastModifiedBy: sessionStorage.getItem('user'),
          lastModifiedDate: new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        });

        // Si la solicitud fue exitosa, muestra un mensaje en el snackbar
        this.snackbarText = 'Sesión editada exitosamente';
        this.snackbar = true;

        // Llama a fetchSessions solo después de que la sesión se haya editado
        await this.fetchSessions(this.idcitaactualizar);

        // Cierra el diálogo de edición
        this.dialogEditarSesion = false;
      } catch (error) {
        // Manejo de errores
        console.error(error);
        this.snackbarText = 'Error al editar la sesión';
        this.snackbar = true;
      }
    },

    restaurarEstadoAgregarSesion() {
      const estadoAgregarSesion = localStorage.getItem('estadoAgregarSesion');
      if (estadoAgregarSesion) {
        try {
          const estadoAgregarSesionObj = JSON.parse(estadoAgregarSesion);
          for (const idCita in estadoAgregarSesionObj) {
            this.$set(this.disabledAgregarSesion, idCita, estadoAgregarSesionObj[idCita]);
          }
        } catch (error) {
          this.snackbarText = 'Error al restaurar el estado del botón de agregar sesión';
          this.snackbar = true;
        }
      }
    },

    guardarEstadoAgregarSesion() {
      const estadoAgregarSesion = {};
      for (const idCita in this.disabledAgregarSesion) {
        estadoAgregarSesion[idCita] = this.disabledAgregarSesion[idCita];
      }
      localStorage.setItem('estadoAgregarSesion', JSON.stringify(estadoAgregarSesion));
    },

    //VERIFICAR SI EL CLIENTE NECESITARA REALIZAR ESTUDIO DESPUES DE UN GLP
    async fetchVerificarGLP(idCita) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/ventas/alerta/${idCita}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          const [{ citasrestantes, idServicio }] = response.data.body;
          this.citasRestantes = citasrestantes;
          this.servicio = idServicio;
          return { citasRestantes: this.citasRestantes, servicio: this.servicio };
        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener las citas restantes';
        this.snackbar = true;
      }
    },

    openDialogNoti() {
      this.alertaGLP = true;
    },
    closeDialogNoti() {
      this.alertaGLP = false;
      this.showNotificationButton = false;
    },

    // Método para formatear la fecha
    formatDate(dateString) { // Verifica el valor de dateString
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'N/A'; // Verifica si la fecha es inválida
      return date.toISOString().split('T')[0]; // 'YYYY-MM-DD'
    },

    handleGuardar() {
      this.snackbarText = 'Sesión registrada exitosamente';
      this.snackbar = true;
      this.alertaformularioNUT_TSHAPE = false;
      this.tipoServicio = null;
    },

    handleCerrar() {
      this.alertaformulario2 = false;
      this.alertaformularioNUT_TSHAPE = false;
      this.tipoServicio = null;
      this.fetchSessions(this.idcitaactualizar);
    },
    handleCerrar2() {
      this.alertaformulario = false;
      this.alertaformularioNUT_TSHAPE = false;
      this.tipoServicio = null;
      this.fetchVentas(this.idclienteactualizar);
    },

    openAgregarSesionDialog(idCita) {
      this.selectedCitaId = idCita;
      this.alertaformulario2 = true;
    },

    openAgregarSesionDialogNUT_TSHAPE(idCita) {
      this.selectedCitaId = idCita;
      this.alertaformularioNUT_TSHAPE = true;
    },

    handleCheckboxChange(item) {
      this.dialogEdit = true;
      this.itemToEdit = item; // Guarda el item que se va a editar
    },

    updateAsistencia(idSesion, asistio) {
      return axios.put(`https://${API_HOST}/api/vista/sesiones/${idSesion}`, {
        asistio: asistio ? 1 : 0 // Asegúrate de enviar 1 o 0 según el valor booleano
      })
        .then(() => {
          this.snackbarText = 'Asistencia Registrada';
          this.snackbar = true;
          this.fetchSessions(this.idcitaactualizar);
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al registrar asistencia';
          this.snackbar = true;
        });
    },

    async confirmEdit() {
      await this.updateAsistencia(this.itemToEdit.idSesion, true);
      this.disabledCheckboxes[this.itemToEdit.idSesion] = true; // Deshabilitar después de confirmar
      this.dialogEdit = false;

      await this.fetchVentas(this.idCliente);

      ////////////////////////////////////////////
      this.fetchVerificarGLP(this.idcitaactualizar)
        .then(({ citasRestantes, servicio }) => {
          if (citasRestantes === 0 && servicio >= 1 && servicio <= 5) {
            this.showNotificationButton = true;
          }
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al verificar GLP';
          this.snackbar = true;
        });

    },

    cancelEdit() {
      this.dialogEdit = false;
    },

    //CONFIRMACION DE ELIMINACION EN CASO DE DAR CLIC A UN CLIENTE POR ERROR
    confirmDeleteSesion(item) {
      this.dialogDelete = true;
      this.sesionAEliminar = item.idSesion;
    },

    //FUNCION PARA ELIMINAR SESIONES
    fetchDeleteSesion() {
      return axios.delete(`https://${API_HOST}/api/vista/sesiones/${this.sesionAEliminar}`)
        .then(() => {
          this.fetchSessions(this.idcitaactualizar);
          this.fetchVentas(this.idCliente);
          this.dialogDelete = false;
          this.sesionAEliminar = null;
          this.snackbarText = 'Sesión eliminada exitosamente';
          this.snackbar = true;

          // Actualizar la variable disabledAgregarSesion
          const totalCitas = this.citas.find(cita => cita.idCita === this.idcitaactualizar).totalcitas;
          const numeroSesiones = this.sesiones.length - 1;
          this.$set(this.disabledAgregarSesion, this.idcitaactualizar, numeroSesiones >= totalCitas);

        })
        .catch(error => {
          error
          this.snackbarText = 'Error al eliminar la sesión';
          this.snackbar = true;
        });
    },

    //CONFIRMACION DE ELIMINACION PARA CUANDO SE QUIERE ELIMINAR UN PAQUETE/VENTA/CITA
    confirmDeleteCita(item) {
      this.citaAEliminar = item.idCita;
      this.dialogDeleteCita = true;
    },

    //FUNCION PARA ELIMINAR PAQUETE/VENTA/CITA
    fetchDeleteCita() {
      return axios.delete(`https://${API_HOST}/api/vista/ventas/${this.citaAEliminar}`)
        .then(() => {
          this.fetchVentas(this.idclienteactualizar);
          this.dialogDeleteCita = false;
          this.citaAEliminar = null;
          this.snackbarText = 'Paquete eliminado exitosamente';
          this.snackbar = true;
        })
        .catch(error => {
          error
          this.snackbarText = 'Error al eliminar el paquete';
          this.snackbar = true;
        });
    },

    formatearFecha(fecha) {
      try {
        return fecha.split('T')[0]; // Devuelve la fecha en formato 'YYYY-MM-DD'
      } catch (error) {
        error
        this.snackbarText = 'Error al formatear la fecha';
        this.snackbar = true;
        return '';
      }
    },

    async fetchPerfil(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/clientes/${idCliente}`);
        if (response.data && response.data.body && typeof response.data.body === 'object') {
          this.nombre = response.data.body.nombre;
          this.apellido = response.data.body.apellido;
          this.selectedClientId = idCliente;
        } else {
          this.snackbarText = 'Error al formatear la fecha';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener datos del cliente';
        this.snackbar = true;
      }
    },

    async fetchVentas(idCliente) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/ventas/citas/${idCliente}`);
        const data = response.data.body;

        if (Array.isArray(data)) {
          this.idclienteactualizar = idCliente;

          // Access the nombre_servicio property of each object in the array
          this.nombreServicio = data.map(item => item.nombre_servicio).join(', ');

          this.citas = data.map(cita => ({
            ...cita,

            fecha: this.formatearFecha(cita.fecha),
            createdBy: cita.createdBy,
            createdDate: cita.createdDate
          }));

          this.citas.forEach(cita => {
            this.total = cita.totalcitas;
            if (cita.totalcitas === 0) {
              this.disabledAgregarSesion[cita.idCita] = true;
            }
          });

        } else {
          this.snackbarText = 'La respuesta de la API no es válida';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener las ventas';
        this.snackbar = true;
      }
    },

    // Método para obtener sesiones
    async fetchSessions(idCita) {
      try {
        const response = await axios.get(`https://${API_HOST}/api/vista/sesiones/${idCita}`);
        if (response.data && response.data.body && Array.isArray(response.data.body)) {
          this.idcitaactualizar = idCita;
          this.sesiones = response.data.body.map(session => ({
            ...session,
            fecha: this.formatearFecha(session.fecha),
            asistio: session.asistio === 1,
            tipoServcio: session.tipoServcio,
          }));
          this.$set(this.sesionesPorCita, idCita, this.sesiones);
          this.sesiones.forEach(session => {
            this.$set(this.checkboxStates, session.idSesion, session.asistio);
            if (session.asistio) {
              this.$set(this.disabledCheckboxes, session.idSesion, true);
            }
          });
          // Actualiza el estado del botón de agregar sesión
          const totalCitas = this.citas.find(cita => cita.idCita === idCita).totalcitas;
          const numeroSesiones = this.sesiones.length;
          const disabledAgregarSesion = numeroSesiones >= totalCitas;
          this.$set(this.disabledAgregarSesion, idCita, disabledAgregarSesion);
          this.guardarEstadoAgregarSesion();

        } else {
          this.snackbarText = 'La respuesta del servidor no contiene datos válidos para sesiones';
          this.snackbar = true;
        }
      } catch (error) {
        error
        this.snackbarText = 'Error al obtener sesiones';
        //this.snackbar = true;
      }
    },

    formattedVentas() {
      const uniqueCitas = new Map();
      this.citas.forEach(cita => {
        if (!uniqueCitas.has(cita.idCita)) {
          uniqueCitas.set(cita.idCita, {
            ...cita,
            fecha: this.formatearFecha(cita.fecha)
          });
        }
      });
      return Array.from(uniqueCitas.values());
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    getColor(totalcitas) {
      if (totalcitas > 100) return 'red'
      else if (totalcitas > 50) return 'orange'
      else return 'green'
    },

    abrirModal3() {
      this.modalVisible3 = true;
    },

    GuardarYCerrar() {
      this.cerrarModal3();
    },

    applyRoleBasedHeaders() {
      this.roleUser = sessionStorage.getItem('role');
      this.filteredHeaders = this.headers.filter((header) => {
        if (this.roleUser === 'standard' && header.value === 'actions') {
          return false;
        }
        return true;
      });
    },

    applyRoleBasedHeaders2(nombreServicio) {
      // Aquí defines tus encabezados completos
      const sesionHeaders = [
        //{ text: 'ID', value: 'idSesion' },
        { text: 'Tipo servicio', value: 'tipoServicio' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Hora', value: 'hora' },
        { text: "Asistió", value: "asistio", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
        { text: 'Detalles', value: 'details', sortable: false },
        // Otros encabezados...
      ];

      return sesionHeaders.filter(header => {
        // Mostrar 'Tipo servicio' solo si nombreServicio es 'NUT+TSHAPE'
        if (nombreServicio === 'NUT+TSHAPE') {
          return true;
        }
        // Ocultar 'Tipo servicio' para otros valores de nombreServicio
        return header.text !== 'Tipo servicio';
      });
    },

  },

  created() {
    this.restaurarEstadoAgregarSesion();
    this.fetchVentas(this.idCliente);
  },

  computed: {
    headers() {
      return [
        { text: 'Servicio', value: 'nombre_servicio' },
        { text: 'Total Citas', value: 'totalcitas' },
        { text: 'Citas Restantes', value: 'citasrestantes' },
        { text: "Agregar Sesion", value: "actions2", sortable: false },
        { text: "Eliminar", value: "actions", sortable: false },
        { text: 'Detalles', value: 'details', sortable: false },
      ];
    },

    sesionHeaders() {
      return [
        //{ text: 'ID', value: 'idSesion' },
        { text: 'Tipo servicio', value: 'tipoServicio' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Hora', value: 'hora' },
        { text: "Asistió", value: "asistio", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
        { text: 'Detalles', value: 'details', sortable: false },
      ];
    },

  },

};
</script>

<style>
.smaller-picker .v-picker__body {
  font-size: 12px;
  /* Ajusta el tamaño del texto en los pickers */
  line-height: 1.5;
  /* Ajusta el espaciado entre líneas si es necesario */
  height: 30px;
}

.w-100 {
  width: 100%;
}
</style>
